import React, { useEffect } from "react";
import { ImFacebook2 } from "react-icons/im";
import { GrInstagram } from "react-icons/gr";
import kontakt1 from "../../assets/kontakt1.jpeg";
import kontakt2 from "../../assets/kontakt2.jpg";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div className="pink-wrapper">
      <div className="container py-5">
        <h2>Kontakt</h2>
        <div className="row white-wrapper">
          <div className="col-lg-6 col-md-12 pb-5 mb-2">
            <h4 className="pr-2">Kontakt</h4>
            <p>Telefon: +372 58892820</p>
            <p>E-mail: info@sweetheartstuudio.ee</p>
            <div className="d-flex mb-4">
              <a
                href="https://www.facebook.com/sweetheartstuudio"
                target="_blank"
                className="nav-link p-0 text-body-secondary"
              >
                <ImFacebook2 size={"2rem"} />
              </a>
              <a
                href="https://www.instagram.com/sweetheartstuudio"
                target="_blank"
                className="nav-link p-0 text-body-secondary px-3"
              >
                <GrInstagram size={"2rem"} />
              </a>
            </div>
            <p>
              Sweetheart OÜ <br />
              reg nr 12773500 <br />
            </p>
            <p className="pb-5">
              LHV SWIFT LHVBEE22 <br />
              IBAN EE237700771009802473
            </p>
            <p>
              <b>Sissepääs Roman Tavast sildiga uksest:</b>
            </p>
            <img
              className="py-3 px-2"
              src={kontakt1}
              alt="Sissepääs Sweetheart stuudio plakati juurest"
            />

            <img
              className="py-2 px-2"
              src={kontakt2}
              alt="Sissepääs Sweetheart stuudiosse kiibiga uksest"
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <h4>Aadress</h4>
            <p>Asume Mustamäe ja Kristiine piiril:</p>
            <p>
              Kadaka tee 36, Tallinn <br />
              II korrus
            </p>
            <iframe
              className="pt-2"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2029.9502471046274!2d24.679690777460245!3d59.417224874656995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469294594fc801cb%3A0x63002a684b51c3f4!2sBake%20My%20Day!5e0!3m2!1set!2see!4v1698656936159!5m2!1set!2see"
              // width="600"
              style={{ border: "0", width: "100%", height: "30rem" }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
